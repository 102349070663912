
import { algoliaGetRule } from '../../libs/utils/algoliaIntegration'
import isEmpty from 'lodash/isEmpty'
import { getAlgoliaProductNormalized } from '../../libs/algolia-normalizer'

export default {
  name: 'sgh-swiper-tiles',
  props: {
    teaserVariant: {
      type: String,
      default: '',
    },
    title: {
      type: String,
      default: '',
    },
    contextRule: {
      type: String,
      default: '',
    },
    priceColor: {
      type: String,
      default: '',
    },
    numMax: {
      type: String,
      default: '',
    },
    hidePrice: {
      type: String,
      default: 'false',
    },
    shadow: {
      type: String,
      default: 'false',
    },
    analyticsId: {
      type: String,
      default: '',
    },
  },
  data() {
    return {
      isSwiperFirst: true,
      products: [],
      refreshKey: 0,
      useAlgolia: window.algoliaConfig?.isActive || false,
      algoliaRule: !!this.$props.contextRule.length,
    }
  },
  created() {
    if (this.useAlgolia & this.algoliaRule) {
      this.getAlgoliaProducts()
    }
  },
  methods: {
    onSlideChange() {
      const swiper = this.$refs?.swiper?.swiper
      this.isSwiperFirst = swiper.isBeginning
    },
    async getAlgoliaProducts() {
      const rule = this.$props.contextRule
      const maxProduct = this.$props.numMax
      const products = await algoliaGetRule(rule, maxProduct).then(result => {
        if (!isEmpty(result.hits)) {
          const { hits } = result
          const productsNormalized = hits.map(product => {
            let item = getAlgoliaProductNormalized(product)
            item.isShadowImg = this.$props.shadow
            item.hidePrice = this.$props.hidePrice
            item.colorPrice = this.$props.priceColor
            return item
          })
          this.products = productsNormalized
          this.refreshKey += 1
        }
      })
    },
  },
}
