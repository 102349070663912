import { GetterTree } from 'vuex'
import { ProductType, PdpStateType } from '../types/pdp.types'


export const getters: GetterTree<PdpStateType, any> = {
  mainProduct: (state) => state.mainProduct || {} as PdpStateType,
  mainProductSizes: (state) => state.mainProductSizes || [] as PdpStateType[],
  recentlySearchedCarousel: (state) => state.recentlySearchedCarousel || [] as ProductType[],
  cmsPlacements: (state) => state.cmsPlacements || {} as PdpStateType,
  colorVariants: (state) => state.colorVariants || {} as PdpStateType,
  findInStore: (state) => state.findInStore || {} as PdpStateType,
  currentProductId: (state) => state.currentProductId || '' as string,
  loadedProducts: (state) => state.loadedProducts || {} as PdpStateType,
  edd: (state) => state.edd || '' as string,
  engravingInfo: (state) => state.engravingInfo || {} as PdpStateType,
  engravingPanelIsOpen: (state) => state.engravingPanelIsOpen || false,
  isAddToBagActive: (state) => state.isAddToBagActive,
  isAddToBagLoading: (state) => state.isAddToBagLoading || false,
  addToBagButtonAction: (state) => state.addToBagButtonAction || '' as string,
  videoPlayer: (state) => state.videoPlayer || [] as PdpStateType[],
}
