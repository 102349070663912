
import { loadSectionTranslations } from '../../i18n-setup'

export default {
  name: 'sgh-cms-product-tile',

  props: {
    product: {
      type: Object,
      default: () => {
        return {}
      },
    },
    analyticsId: {
      type: String,
      default: '',
    },
    cmsDescription: {
      type: String,
      default: '',
    },
    teaserVariant: {
      type: String,
      default: 'wall',
    },
    index: {
      type: Number,
    },
  },

  computed: {
    hasPriceMarkDown() {
      return this.product.onSale !== undefined
    },

    isVariantBox() {
      return this.teaserVariant === 'box'
    },

    isVariantFaces() {
      return this.teaserVariant === 'faces'
    },

    isVariantWall() {
      return (
        this.teaserVariant === 'wall' ||
        this.teaserVariant === 'wall-2023' ||
        this.teaserVariant === 'wall-2023-best-seller'
      )
    },

    tileDescription() {
      return this.cmsDescription || this.product.description || ''
    },

    isViewportDesktop() {
      return this.$mq === 'desktop'
    },
    shadowImg() {
      return {
        srcImage: `https://assets.sunglasshut.com/is/image/LuxotticaRetail/${this.product.upc}__STD__shad__fr.png?impolicy=SGH_bgtransparent&width=640`,
        srcHoverImage: `https://assets.sunglasshut.com/is/image/LuxotticaRetail/${this.product.upc}__STD__shad__qt.png?impolicy=SGH_bgtransparent&width=640`,
      }
    },
    hidePrice() {
      return this.product.hidePrice && this.product.hidePrice === 'true'
    },

    colorPrice() {
      return this.product.colorPrice && this.product.colorPrice === 'red'
        ? 'text-red-berry-2'
        : 'text-mine-shaft'
    },

    tileImages() {
      const { img, imgHover, overrideImage, overrideHoverImage, isShadowImg } = this.product
      const srcImagePhat =
        isShadowImg && isShadowImg === 'true' ? this.shadowImg.srcImage : overrideImage || imgHover
      const srcHoverImage =
        isShadowImg && isShadowImg === 'true'
          ? this.shadowImg.srcHoverImage
          : overrideHoverImage || (overrideImage ? null : img)
      return {
        isHoverImageVisible: this.teaserVariant !== 'faces' && !overrideHoverImage,
        srcImage: srcImagePhat,
        srcHoverImage: srcHoverImage,
      }
    },
  },

  methods: {
    getDataDescription() {
      const modelName =
        this.product.modelName !== undefined ? this.product.modelName.replace(/ /g, '_') || '' : ''
      const UPC = this.product.upc || ''
      return modelName + '_' + UPC
    },
  },
  mounted() {
    loadSectionTranslations('cms')
  },
}
