
import { cmsColor } from '../../../libs/utils/const'

export default {
  name: 'sgh-category-module-item',
  props: {
    url: {
      type: String,
      default: '',
    },
    discount: {
      type: String,
      default: '',
    },
    badgeColors: {
      type: Array,
      default: () => null,
    },
  },

  computed: {
    isDiscount() {
      return this.discount !== ''
    },
    hasSwiperParent() {
      const elements = this.$parent.$slots.default
        ? this.$parent.$slots.default.filter(
            vnode => vnode.componentOptions?.tag == 'sgh-category-module-item'
          )
        : 0
      return elements.length
    },
    badgeColorStyle() {
      let style = ''

      if (this.badgeColors) {
        style = {
          '--badge-color-top': `${cmsColor[this.badgeColors[0].slice(17)]}`,
          '--badge-color-mid': `${cmsColor[this.badgeColors[1].slice(17)]}`,
          '--badge-color-back': `${cmsColor[this.badgeColors[2].slice(17)]}`,
        }
      }
      return style
    },
  },
}
