
import { loadSectionTranslations } from '../../i18n-setup'

export default {
  name: 'sgh-payment-klarna',

  mounted: function() {
    loadSectionTranslations('minicart')
  },
}
