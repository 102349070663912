
export default {
  name: 'sgh-brand-wall',
  props: {
    title: {
      type: String,
      required: true,
    },
    viewAllLink: {
      type: String,
      required: true,
    },
    viewAll: {
      type: String,
      required: true,
    },
    dataElementIdViewAll: {
      type: String,
      required: false,
    },
    dataDescriptionViewAll: {
      type: String,
      required: false,
    },
  },
}
