
import { loadSectionTranslations } from '../../i18n-setup'
import sghPaymentKlarna from './sgh-payment-klarna.vue'
import sghPaymentPaypal from './sgh-payment-paypal.vue'
import { klarnaId, paypalId } from '../../libs/wcs/payment'

export default {
  name: 'sgh-payment-info-popup',
  components: {
    sghPaymentKlarna,
    sghPaymentPaypal,
  },
  props: {
    visible: {
      type: Boolean,
      default: false,
    },
    id: {
      type: String,
      default: '',
    },
  },
  data: function() {
    return {
      klarnaId,
      paypalId,
    }
  },
  computed: {
    popupId() {
      switch (this.id) {
        case klarnaId:
          return 'paymentPopupKlarna'
        case paypalId:
          return 'paymentPopupPaypal'
      }
      return ''
    },
  },
  watch: {
    visible(newVal, oldVal) {
      if (oldVal === false && newVal === true) {
        this.openPop()
      }
    },
  },
  mounted: function() {
    loadSectionTranslations('minicart')
  },
  methods: {
    openPop() {
      this.openPopup = true
      this.$refs.paymentPopup.isOpen = true
    },
  },
}
