
export default {
  name: 'sgh-service-carousel',
  props: {
    titleModule: {
      type: String,
      default: '',
    },
  },
}
