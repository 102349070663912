
import { algoliaGetRule } from '../../libs/utils/algoliaIntegration'
import isEmpty from 'lodash/isEmpty'
import { getAlgoliaProductNormalized } from '../../libs/algolia-normalizer'

export default {
  name: 'cms-wall-slider',
  props: {
    desktopRows: {
      type: Number,
      default: 2,
    },
    mobileRows: {
      type: Number,
      default: 3,
    },
    numMax: {
      type: String,
      default: '',
    },
    contextRule: {
      type: String,
      default: '',
    },
    teaserVariant: {
      type: String,
      default: '',
    },
    analyticsId: {
      type: String,
      default: '',
    },
  },
  data() {
    return {
      products: [],
      refreshKey: 0,
      useAlgolia: window.algoliaConfig?.isActive || false,
      algoliaRule: !!this.$props.contextRule.length,
    }
  },
  created() {
    if (this.useAlgolia && this.algoliaRule) {
      this.getAlgoliaProducts()
    }
  },
  computed: {
    swiperColumns() {
      const IS_MOBILE = this.$mq === 'tablet'
      const IS_TABLET = this.$mq === 'tabletLandscape'
      if (IS_MOBILE) return 2
      if (IS_TABLET) return 3

      return 6
    },

    swiperRows() {
      const IS_MOBILE = this.$mq === 'tablet'
      const IS_TABLET = this.$mq === 'tabletLandscape'
      if (IS_MOBILE) return this.mobileRows
      if (IS_TABLET) return 2
      return this.desktopRows
    },
  },
  methods: {
    async getAlgoliaProducts() {
      const rule = this.contextRule
      const maxProduct = this.numMax
      await algoliaGetRule(rule, maxProduct).then(result => {
        if (!isEmpty(result.hits)) {
          const { hits } = result
          const productsNormalized = hits.map((product, index) => {
            let item = getAlgoliaProductNormalized(product, false, 'cms-wall')
            return item
          })
          this.products = productsNormalized
          if (window.MonetateHelper && window.MonetateHelper.monenateComponentInstance) {
            window.MonetateHelper.monenateComponentInstance.trackHomePageCarousel(this.products)
          }
          this.refreshKey += 1
        }
      })
    },
  },
}
