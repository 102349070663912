
import { loadSectionTranslations } from '../../i18n-setup'
import { getCurrentCountry, getCurrencySymbol } from '../../libs/utils/currentCountry'
import { formatPrice } from '../../libs/utils/string'
export default {
  name: 'sun-love-protection',
  props: {
    insurance: {
      type: [Object, String],
      required: true,
    },
    parentItemId: {
      type: String,
      default: '',
    },
  },
  data() {
    return {
      insuranceItemIdNum: null,
      activeLayerClass: 'prevent-active',
      bodyEl: document.querySelector('body'),
      insuranceObject: null,
    }
  },
  computed: {
    uniqueId() {
      return `insurance-${Date.now()}`
    },
    priceWIthCurrency() {
      if (this.isCaFr) {
        return `${formatPrice(this.insuranceObject.offerPrice)} ${getCurrencySymbol()}`
      } else {
        return getCurrencySymbol() + formatPrice(this.insuranceObject.offerPrice)
      }
    },
    getLabel() {
      return this.$t('sun_love_protection_title', [this.priceWIthCurrency])
    },
    isCaFr() {
      return getCurrentCountry() === 'ca-fr'
    },
  },
  methods: {
    checkboxHandler(e) {
      e.target.checked ? this.addInsurance(e) : this.removeInsurance()
    },
    addInsurance(e) {
      const params = []
      params['page'] = this.getPageType()
      params['catalogOrigin'] = ''
      params['catEntryId'] = this.insuranceObject.catEntryId
      params['parentItemId'] = this.parentItemId
      localStorage.setItem('insuranceUniqueId', e.target.id)
      this.managePreventLayer(true)

      window.categoryDisplayJS.AddItem2ShopCartAjax(this.insuranceObject.catEntryId, 1, params)
    },
    removeInsurance() {
      this.managePreventLayer(true)
      window.CheckoutHelperJS.deleteFromCart(this.insuranceItemIdNum, false, true)
    },
    checkInitialValue() {
      //set initial value
      if (this.insuranceObject.inCart) {
        this.insuranceItemIdNum = this.insuranceObject.insuranceItemId
      }
      localStorage.setItem('insuranceUniqueId', '')
    },
    setInsuranceItemId(event) {
      //this Id number takes for insurance remove
      this.insuranceItemIdNum = event.detail
      localStorage.setItem('insuranceUniqueId', '')
      this.managePreventLayer()
      if (this.getPageType() === 'cart') {
        this.rerenderRefreshAria()
      }
    },
    managePreventLayer(flag) {
      if (flag) {
        this.bodyEl.classList.add(this.activeLayerClass)
      } else {
        this.bodyEl.classList.remove(this.activeLayerClass)
      }
    },
    getPageType() {
      let pageType = null
      switch (window.pageType) {
        case 'product':
          pageType = 'pdp'
          break
        case 'cart':
          pageType = 'cart'
          break
        default:
          pageType = ''
          break
      }
      return pageType
    },
    rerenderRefreshAria() {
      window.wc.render.updateContext('ShopCartPaginationDisplay_Context', {
        appliedPromo: '',
        errorMessage: '',
        errorKey: '',
        promotionView: '',
      })
    },
  },
  created() {
    this.insuranceObject =
      typeof this.insurance === 'object' ? this.insurance : JSON.parse(this.insurance)
  },
  mounted() {
    loadSectionTranslations('minicart')
    this.checkInitialValue()
  },
}
