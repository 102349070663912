
export default {
  name: 'sgh-service-item',
  props: {
    titleService: {
      type: String,
      default: '',
    },
    fullBlock: {
      type: Boolean,
      default: false,
    },
    linkService: {
      type: String,
      default: '',
    },
    bgColor: {
      type: String,
      default: 'cms-custom-color-white-smoke',
    },
    textColor: {
      type: String,
      default: 'cms-custom-text-color-black',
    },
    numberBannersParent: {
      type: Number,
      default: 2,
    },
  },

  computed: {
    isIconAvailable() {
      return !!this.$slots.icon_service
    },
    isTitleAvailable() {
      return !!this.$slots.title_service
    },
    isTextAvailable() {
      return !!this.$slots.text_service
    },
    isFootnoteAvailable() {
      return !!this.$slots.footnote_service
    },
    isCta() {
      return !!this.$slots.cta && !this.fullBlock
    },
    backgroundColor() {
      return this.bgColor ? this.bgColor : ''
    },
    isMobile() {
      return this.$mq == 'tablet' || this.$mq == 'tabletLandscape'
    },
  },
}
