
export default {
  name: 'SghFullWidth',
  props: {
    teaserLink: {
      type: String,
      default: '',
    },
    isBgVideo: {
      type: Boolean,
      default: false,
    },
    videoControllerPosition: {
      type: String,
      default: 'left',
    },
    textColor: {
      type: String,
      default: 'cms-custom-text-color-black',
    },
    textAlign: {
      type: String,
      default: 'left',
      validator: function(value) {
        return ['left', 'right', 'center'].indexOf(value) !== -1
      },
    },
    imageSize: {
      type: String,
      default: 'default',
      validator(value) {
        return ['default', 'l', 'm', 's'].indexOf(value) !== -1
      },
    },
    dataElementId: {
      type: String,
      default: '',

    },
  },
  data() {
    return {
      isVideoPlaying: false,
      isVideoMuted: true,
      isLoaded:false,
    }
  },
  computed: {
    getDataDescriptionText() {
      return this.$slots?.text[0]?.children[0].text || ''
    },
    textAlignmentClass() {
      return `text-${this.textAlign}`
    },
    imageSizeClass() {
      switch (this.imageSize) {
        case 'l':
          return 'full-width--images-l'
        case 'm':
          return 'full-width--images-m'
        default:
          return 'full-width--images-s'
      }
    },
    videoControllerTopClass() {
      switch (this.imageSize) {
        case 'l':
          return 'video-controller-top-l'
        case 'm':
          return 'video-controller-top-m'
        default:
          return 'video-controller-top-s'
      }
    },
    textMaxWidthClass() {
      switch(this.textAlign) {
        case 'center': 
          return 'text-center-max-width'
        case 'right':
          return 'text-right-max-width'
        default:
          return 'text-left-max-width'
      }
    },
  },
  mounted() {
  this.$nextTick(async () => {
    const slot = this.$refs.image
    if (slot) {

      const image = slot.querySelector('img')
      const video = slot.querySelector('video')
     
      if (image) {
        try {
          await this.imgIsLoaded(image)
          image.addEventListener('load', this.handleMediaLoad)
        } catch (error) {
          console.error('Image failed to load', error)
        }
      }

      if (video) {
        video.addEventListener('loadeddata', this.handleMediaLoad)
      }

      if (this.isBgVideo) {
        this.setVideoSrc()
        this.isVideoAutoplay()
      }

    } else {
      console.error('slot is still undefined')
    }
  })
},

methods: {
  async imgIsLoaded(img) {
    if (!img || img.loading === 'lazy') {
      this.isLoaded = true
      return
    }

    return new Promise((resolve, reject) => {
      img.addEventListener('load', () => {
        this.isLoaded = true
        resolve()
      })

      img.addEventListener('error', () => {
        reject(new Error('Image failed to load'))
      })
    })
  },

    handleMediaLoad() {
      this.isLoaded = true
    },
    playVideo() {
      const video = this.$refs.image?.children[0]
      if (video) {
        if (video.paused) {
          video.play()
          this.isVideoPlaying = true
        } else {
          video.pause()
          this.isVideoPlaying = false
        }
      }
    },
    isVideoAutoplay() {
      const video = this.$refs.image?.children[0]
      if (video.autoplay) {
        return (this.isVideoPlaying = true)
      }
    },
    audioControllVideo() {
      const audio = this.$refs.image?.children[0]
      if (audio) {
        if (audio.muted) {
          audio.muted = false
          this.isVideoMuted = false
        } else {
          audio.muted = true
          this.isVideoMuted = true
        }
      }
    },
    setVideoSrc() {
      if (this.isBgVideo) {
        const video = this.$refs.image?.children[0]
        const dataMobileSrc = this.$refs.image?.children[0]?.dataset.mobileSrc
        const dataDesktopSrc = this.$refs.image?.children[0]?.dataset.desktopSrc
        const dataMobilePoster = this.$refs.image?.children[0]?.dataset.mobilePoster
        const dataDesktopPoster = this.$refs.image?.children[0]?.dataset.desktopPoster
        if (dataDesktopSrc && dataMobileSrc) {
          this.isMobile ? video.children[0].src : (video.children[0].src = dataDesktopSrc)
        }
        if (dataDesktopPoster && dataMobilePoster) {
          this.isMobile ? (video.poster = dataMobilePoster) : (video.poster = dataDesktopPoster)
        }
        this.isVideoAutoplay()
      }
    },
  },
}
