import {
  PdpStateType,
  ProductType,
  MainProductType,
  ColorVariantsType,
  FindInStoreStateType,
  SizeType,
  EngravingStateType,
} from '../types/pdp.types'



export const UPDATE_MAIN_PRODUCT = 'UPDATE_MAIN_PRODUCT'
export const UPDATE_RECENTLY_SEARCHED_CAROUSEL = 'UPDATE_RECENTLY_SEARCHED_CAROUSEL'
export const UPDATE_LOAD_CMS_PLACEMENTS = 'UPDATE_LOAD_CMS_PLACEMENTS'
export const UPDATE_COLOR_VARIANTS = 'UPDATE_COLOR_VARIANTS'
export const UPDATE_LOAD_FI = 'UPDATE_LOAD_FI'
export const RESET_FI = 'RESET_FI'
export const UPDATE_CURRENT_PRODUCT_ID = 'UPDATE_CURRENT_PRODUCT_ID'
export const UPDATE_LOADED_PRODUCTS = 'UPDATE_LOADED_PRODUCTS'
export const UPDATE_PRODUCT_SIZES = 'UPDATE_PRODUCT_SIZES'
export const UPDATE_EDD = 'UPDATE_EDD'
export const UPDATE_ENGRAVING_INFO = 'UPDATE_ENGRAVING_INFO'
export const UPDATE_ENGRAVING_PANEL_VISIBILITY = 'UPDATE_ENGRAVING_PANEL_VISIBILITY'
export const SET_ADD_TO_BAG_ACTIVE = 'SET_ADD_TO_BAG_ACTIVE'
export const SET_ADD_TO_BAG_LOADING = 'SET_ADD_TO_BAG_LOADING'
export const SET_ADD_TO_BAG_TEXT = 'SET_ADD_TO_BAG_TEXT'
export const SET_VIDEO_PLAYER = 'SET_VIDEO_PLAYER'



export const mutations = {
  [UPDATE_CURRENT_PRODUCT_ID]: function (state: PdpStateType, payload: string) {
    state.currentProductId = payload
  },
  [UPDATE_LOADED_PRODUCTS]: function (state: PdpStateType, payload: MainProductType) {
    state.loadedProducts.push(payload)
  },
  [UPDATE_MAIN_PRODUCT]: function (state: PdpStateType, payload: MainProductType) {
    state.mainProduct = payload
  },
  [UPDATE_COLOR_VARIANTS]: function (state: PdpStateType, payload: ColorVariantsType[]) {
    state.colorVariants = payload
  },
  [UPDATE_RECENTLY_SEARCHED_CAROUSEL]: function (state: PdpStateType, payload: ProductType[]) {
    state.recentlySearchedCarousel = payload || [] as ProductType[]
  },
  [UPDATE_LOAD_CMS_PLACEMENTS]: function (state: PdpStateType, payload: []) {
    state.cmsPlacements = payload
  },
  [UPDATE_LOAD_FI]: function (state: PdpStateType, payload: [] ) {
    state.findInStore = { ...state.findInStore, ...payload }
  },
  [RESET_FI]: function (state: PdpStateType) {
    state.findInStore = {} as FindInStoreStateType
  },
  [UPDATE_PRODUCT_SIZES]: function (state: PdpStateType, payload: SizeType[]) {
    state.mainProductSizes = payload
  },
  [UPDATE_ENGRAVING_INFO]: function (state: PdpStateType, payload: EngravingStateType) {
    state.engravingInfo = payload
  },
  [UPDATE_ENGRAVING_PANEL_VISIBILITY]: function (state: PdpStateType, payload: boolean) {
    state.engravingPanelIsOpen = payload
  },
  [UPDATE_EDD]: function (state: PdpStateType, payload: string) {
    state.edd = payload
  },
  [SET_ADD_TO_BAG_ACTIVE]: function (state: PdpStateType, payload: boolean) {
    state.isAddToBagActive = payload
  },
  [SET_ADD_TO_BAG_LOADING]: function (state: PdpStateType, payload: boolean) {
    state.isAddToBagLoading = payload
  },
  [SET_ADD_TO_BAG_TEXT]: function(state: PdpStateType, payload: string) {
    state.addToBagButtonAction = payload
  },
  [SET_VIDEO_PLAYER]: function (state: PdpStateType, payload: videoPlayerType[]) {
    state.videoPlayer = payload
  }
}
