
export default {
  name: 'sgh-service-carousel-item',
  props: {
    linkCta: {
      type: String,
      default: '',
    },
    dataElementId: {
      type: String,
      default: '',
    },
  },
  data() {
    return {
      isHovered: false,
    }
  },
  computed: {
    hasHoverImg() {
      return !!this.$slots['picture-hover']
    },
  },
  methods: {
    handleMouseOver() {
      this.isHovered = true
    },
    handleMouseOut() {
      this.isHovered = false
    },
  },
}
