const algoliaDashFilters = () => {
  return {
    'attributes_translated.POLARIZED': {
      icon: 'polarized',
      count: 'POLARIZED',
    },
    'attributes_translated.LIMITED_EDITION': {
      icon: 'limited_edition',
      count: 'TRUE',
    },
    'attributes_translated.SALE': {
      icon: 'sale',
      count: 'TRUE',
    },
    'attributes_translated.PRODUCT_TYPE': {
      icon: 'electronics',
      count: 'Electronics',
    },
    'attributes_translated.FRAME_SHAPE_FACET?Pilot': {
      icon: 'pilot',
      count: 'Pilot',
    },
    'attributes_translated.CHANNEL_DETAILS?ONLINE ONLY': {
      icon: 'channel_details',
      count: 'ONLINE ONLY',
    },
    'attributes_translated.CHANNEL_DETAILS?EXCLUSIVE SGH': {
      icon: 'exclusive',
      count: 'EXCLUSIVE SGH',
    },
    'attributes_translated.CUSTOMIZABLE': {
      icon: 'make_it_yours',
      count: 'TRUE',
    },
    'attributes_translated.ENGRAVABLE': {
      icon: 'engraving',
      count: 'TRUE',
    },
    'attributes_translated.ROXABLE': {
      icon: 'prescription',
      count: 'TRUE',
    },
  }
}
export default algoliaDashFilters
