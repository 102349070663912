
import sghPaymentInfoPopup from './sgh-payment-info-popup.vue'
import { loadSectionTranslations } from '../../i18n-setup'
import { paypalId, klarnaId } from '../../libs/wcs/payment'

export default {
  name: 'sgh-payment-info',
  components: {
    sghPaymentInfoPopup,
  },
  props: {
    id: {
      type: String,
      default: '',
    },
  },
  data() {
    return {
      visible: false,
    }
  },

  computed: {
    paymentLabel() {
      switch (this.id) {
        case klarnaId:
          return this.$t('klarna_info')
        case paypalId:
          return this.$t('paypal_info')
      }
      return ''
    },
  },
  mounted: function() {
    loadSectionTranslations('minicart')
  },

  mounted() {
    this.$root.$on('sghPopupTypeClosed', popupId => {
      popupId === 'paymentPopupKlarna' || popupId === 'paymentPopupPaypal'
      this.closePaymentPopup()
    })
  },

  methods: {
    openPaymentPopup() {
      this.visible = true
    },
    closePaymentPopup() {
      this.visible = false
    },
  },
}
