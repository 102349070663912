import axios, { AxiosInstance } from 'axios';
import MockAdapter from 'axios-mock-adapter';
import { customMockApi } from '../axiosMock.types';
import { isLocalHost } from '../../scripts/libs/utils/url';

// Create an instance of axios
const axiosInstance: AxiosInstance = axios.create();

// Create an instance of MockAdapter and pass the axios instance to it
const mock = new MockAdapter(axiosInstance);

// Create a custom mock API
const mockApi: customMockApi = {
  get: (url, result, config) => {
    return mock.onGet(url, config).reply(200, { result });
  },
  post: (url, result, data, config) => {
    return mock.onPost(url, data, config?.headers).reply(200, { result });
  },
  put: (url, result, data, config) => {
    return mock.onPut(url, data, config?.headers).reply(200, { result });
  },
  delete: (url, result, config) => {
    return mock.onDelete(url, config).reply(200, { result });
  },
};

// Dynamically import axiosMockCall only if it's a local environment
if (isLocalHost()) {
  import('./axiosMockCall').then(module => {
    const axiosMockCall = module.default;
    return axiosMockCall(mockApi);
  }).catch(err => {
    console.error('Error loading axiosMockCall:', err);
  });
}
export default axiosInstance;